class languageSwitcher {
    constructor(selector) {
        console.log('asd');
        this.selector = selector;

        this.button = this.selector.querySelector('[data-element="toggle"]');
        this.container = this.selector.querySelector('[data-element="container"]');

        this._onClickButton = this._onClickButton.bind(this);

        window.addEventListener('click', (e) => {
            if(!e.target.closest('.lang-menu-mobile')) {
                this.container.style.display = 'none';
                this.selector.classList.remove('active');
            }
        });

        if(this.button !== null)
            this.button.addEventListener('click', this._onClickButton);
    }

    _onClickButton(e) {
        e.preventDefault();
        if(!this.selector.classList.contains('active')) {
            this.container.style.display = 'flex';
            this.selector.classList.add('active');
        } else {
            this.container.style.display = 'none';
            this.selector.classList.remove('active');
        }
    }
}

export default languageSwitcher;